import { harvestApi } from "@/api/harvest.api"

const getActiveHarvest = async () => {
  const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
  const response = await harvestApi.getActiveHarvestData(enterpriseId)
  return response.data
}

export const harvestService = {
  getActiveHarvest
}