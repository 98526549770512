import { mapState, mapMutations, mapGetters } from 'vuex'
import { filterRulesMapped } from '@/tools/filterRules'
import { dateSortFunction } from '@/tools/functions'
import moment from 'moment'
import service from '@/middleware'
import { detailsSpeedMap } from '@/constants/constants'
import { clone } from 'ramda'
import workedAreaService from '@/business/workedAreaService'

export default {
  name: 'HarvestedAreaDetailReport',
  components: {
  },
  props: {
    // mapReferences: Array
    speedDetailMapConfig: {
      type: Array,
      required: true
    },
    // polígonos de las UM del reporte
    driveUnitPolygons: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'id',
    mapData: [],
    mapConfiguration: [],
    intervalId: null,
    loadingSpinner: true,
    loadingTable: true,
    middleware: service.http,
    driveUnitName: undefined,
    mapStateReferences: []
  }),
  computed: {
    ...mapGetters({
      getDetails: 'breadcrumb/getDetails',
      mapLinePatterns: 'app/getMapLinePatterns',
      mapLinePatternsEnabled: 'app/getMapLinePatternsEnabled'
    }),
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    })
  },
  created () {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    if (this.selectedItem) {
      this.getData(this.selectedItem)
    }
  },
  beforeDestroy () {
    this.closeReportDefer()
    this.resetEntitySelector()
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    refreshTable () {
      if (this.selectedItem) {
        this.getData(this.selectedItem, true)
      }
    },
    resize () {
    },
    /**
     * Sólo se guardan los rangos de velocidades del mapa de velocidades
     * Las otras referencias están relacionadas al detalle del reporte generado
     * @param {*} references
     */
    mapReferenceChange (references) {
      // this.$emit('detailMapReferenceChange', references)

      // TODO: mejorar esto, en vez de usar índices, usar un valor que identifique al mapa y al grupo
      const speedMapReferences = references[1]
      if (speedMapReferences != null) {
        const speedMapReferencesGroup = speedMapReferences[1]
        if (speedMapReferencesGroup != null) {
          this.$emit('detailMapReferenceChange', speedMapReferencesGroup.references)
        }
      }
    },
    mapResetFilter (idMap, groupName, references, mapsId) {
      let refsReset = references
      if (mapsId[0] === idMap) {
        if (refsReset[0][0].name === groupName) {
          refsReset[0][0].references = [
            {
              name: this.driveUnitName,
              color: 'noColor',
              isInterval: false,
              title: this.driveUnitName
            }
          ]
        } else if (refsReset[0][1].name === groupName) {
          refsReset[0][1].references = this.mapStateReferences
        }
      } else if (mapsId[1] === idMap) {
        if (refsReset[1][0].name === groupName) {
          refsReset[1][0].references = [
            {
              name: this.driveUnitName,
              color: 'noColor',
              isInterval: false,
              title: this.driveUnitName
            }
          ]
        } else if (refsReset[1][1].name === groupName) {
          refsReset[1][1].references = detailsSpeedMap.references
        }
      }

      const maps = clone(this.mapConfiguration)
      maps.forEach((map, i) => {
        map.groups.forEach(group => {
          const ref = refsReset[i].find(r => r.name === group.name)
          group.references = ref.references || group.references
        })
      })
      this.mapConfiguration.cleanAndUpdate(maps)

      // this.$emit('detailMapResetFilter', refsReset)
      this.mapReferenceChange(refsReset)
    },
    getAreaMapsForDetail (data, driveUnitId, maps) {
      const seriesWorkedArea = []
      const referencesWorkedArea = []
      const seriesSpeed = {}
      const seriesState = {}

      const driveUnit = this.driveUnitPolygons[driveUnitId]
      if (driveUnit != null && driveUnit.serie != null && driveUnit.reference != null) {
        referencesWorkedArea.push(driveUnit.reference)
        seriesWorkedArea.push(driveUnit.serie)
      }

      // const hours = parseInt((to - from) / 3600, 10)
      // let ultima = null

      let pattern
      data.forEach((d) => {
        const state = (d.thingStateName !== 'UNDEFINED' && d.thingStateName !== '' && d.thingStateName !== null) ? d.thingStateName : 'SIN ESTADO'
        // let graficar = !ultima || d.thingStateName !== ultima.thingStateName || Math.abs(d.direction - ultima.direction) >= (hours > 1 ? 10 : 0) ||
        // Util.distance(d.position[0], d.position[1], ultima.position[0], ultima.position[1], 'K') / 1000 >= (hours > 1 ? 10 : 0) ||
        // Math.abs(d.speed.value - ultima.speed.value) >= 1
        // graficar = true
        // if (graficar) {
        // ultima = d

        if (this.mapLinePatternsEnabled && this.mapLinePatterns != null && this.mapLinePatterns[d.typeKey] != null) {
          pattern = {
            type: 'CUSTOM',
            icon: this.mapLinePatterns[d.typeKey],
            widthInMeters: d.thingWorkWidth != null
              ? d.thingWorkWidth
              : (d.driveUnitHeadWidth != null ? d.driveUnitHeadWidth : 1)
          }
        } else {
          pattern = undefined
        }

        if (!seriesSpeed[d.thingId]) {
          seriesSpeed[d.thingId] = {
            type: 'LINE',
            pattern,
            points: [],
            showMarkers: false
          }
        }
        if (!seriesState[d.thingId]) {
          seriesState[d.thingId] = {
            type: 'LINE',
            pattern,
            points: [],
            showMarkers: false
          }
        }
        seriesSpeed[d.thingId].points.push({
          lat: d.position.value[0],
          lng: d.position.value[1],
          value: d.speed.value,
          filters: [state]
        })
        seriesState[d.thingId].points.push({
          lat: d.position.value[0],
          lng: d.position.value[1],
          value: state,
          filters: []
        })

        if (!maps.detailSpeedMap.filters[0].values.includes(state)) {
          maps.detailSpeedMap.filters[0].values.push(state)
        }
        // }
      })
      maps.detailStateMap.groups[0].series = seriesWorkedArea
      maps.detailStateMap.groups[0].references = referencesWorkedArea
      maps.detailStateMap.groups[1].series = Object.values(seriesState)
      maps.detailStateMap.id = moment().unix()

      maps.detailSpeedMap.groups[0].series = seriesWorkedArea
      maps.detailSpeedMap.groups[0].references = referencesWorkedArea
      maps.detailSpeedMap.groups[1].series = Object.values(seriesSpeed)
      maps.detailSpeedMap.id = moment().unix() + 1

      if (this.speedDetailMapConfig != null && this.speedDetailMapConfig.length > 0) {
        maps.detailSpeedMap.groups[1].references.cleanAndUpdate(this.speedDetailMapConfig)
      }

      const result = [maps.detailStateMap, maps.detailSpeedMap]

      /*
      if (this.mapReferences) {
        result.forEach((map, i) => {
          map.groups.forEach(group => {
            const ref = this.mapReferences[i].find(r => r.name === group.name)
            group.references = (ref && ref.references) || group.references
          })
        })
      }
      */
      return result
    },
    /*
      {
        name: driveUnitName,
        color: 'noColor',
        isInterval: false,
        title: driveUnitName,
      },
    */
    getData (data, refresh = false) {
      const that = this
      that.driveUnitName = data.driveUnitName
      if (data.autoReport) {
        that.loadingTable = true
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        workedAreaService.getDetail(data.driveUnitId, data.timeStampFrom, data.timeStampTo, data.types, data.activities, data.thingIds, data.sector).then(response => {
          if (!refresh) {
            that.tableHeaders.cleanAndUpdate(response.headers)
            that.setTableFilterRules(response.headers)
            that.setSortFunctions()
          }
          that.tableData.cleanAndUpdate(response.data)
          that.mapConfiguration = this.getAreaMapsForDetail(response.data, data.driveUnitId, response.maps, data.timeStampFrom, data.timeStampTo)
          that.mapStateReferences = response.mapStateReferences
          if (that.loadingSpinner) that.commitVisible({ visible: false })
          that.loadingTable = false
        })
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          function () {
            that.loadingTable = true
            if (that.loadingSpinner) that.commitVisible({ visible: true })
            workedAreaService.getDetail(data.driveUnitId, data.timeStampFrom, data.timeStampTo, data.types, data.activities, data.thingIds, data.sector).then(response => {
              that.tableData.cleanAndUpdate(response.data)
              that.mapConfiguration = this.getAreaMapsForDetail(response.data, data.driveUnitId, response.maps, data.timeStampFrom, data.timeStampTo)
              that.mapStateReferences = response.mapStateReferences
              if (that.loadingSpinner) that.commitVisible({ visible: false })
              that.loadingTable = false
            })
          },
          data.autoReportMinutes * 60 * 1000)
      } else {
        if (that.loadingSpinner) that.commitVisible({ visible: true })
        that.loadingTable = true
        workedAreaService.getDetail(data.driveUnitId, data.timeStampFrom, data.timeStampTo, data.types, data.activities, data.thingIds, data.sector).then(response => {
          if (!refresh) {
            that.tableHeaders.cleanAndUpdate(response.headers)
            that.setTableFilterRules(response.headers)
            that.setSortFunctions()
          }
          that.tableData.cleanAndUpdate(response.data)
          that.mapConfiguration = this.getAreaMapsForDetail(response.data, data.driveUnitId, response.maps, data.timeStampFrom, data.timeStampTo)
          that.mapStateReferences = response.mapStateReferences
          if (that.loadingSpinner) that.commitVisible({ visible: false })
          that.loadingTable = false
        })
      }
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    clickRow () {
    },
    dataChangeEventHandler (newData) {
      this.mapData.cleanAndUpdate(newData)
    },
    setSortFunctions () {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'date').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY') }
      this.tableHeaders.find(header => header.value === 'time').sort = (a, b) => { return dateSortFunction(a, b, 'HH:mm:ss') }
    }
  },
  watch: {
    selectedItem () {
      if (this.selectedItem) {
        this.getData(this.selectedItem)
      }
    }
  }
}
