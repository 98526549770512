import Vue from 'vue'
import i18n from '@/i18n'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import reportStorageApi from '@/api/report-storage.api'
import activityApi from '@/api/activity.api'
import { filterRulesMapped } from '@/tools/filterRules'
import thingApi from '@/api/thing.api'
import sectorApi from '@/api/sector.api'
import driveUnitApi from '@/api/drive-unit.api'
import { SnackbarStyle, areaPercentageMap } from '@/constants/constants'
import { getChildrenFromList, getDateTimeRange, selectorDateTimeValidation, ISODateFromUnixTimestamp } from '@/tools/functions'
import moment from 'moment'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import HarvestedAreaDetailReport from '@/components/drive-units/reports/harvested-area/detail/HarvestedAreaDetailReport.vue'
import { configurationService } from '@/business/configurationService'
import service from '@/middleware'
import html2canvas from 'html2canvas'
import selectorService from '@/business/selectorService'
import { clone } from 'ramda'
import workedAreaService from '@/business/workedAreaService'
import { harvestService } from '@/business/harvestService'

export default {
  name: 'HarvestedAreaReport',
  components: {
    SelectorComponent,
    BreadcrumbComponent,
    HarvestedAreaDetailReport
  },
  props: {},
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    middleware: service.http,
    tableData: [],
    tableDataKey: 'harvesterId',
    chartConfiguration: [],
    mapConfiguration: [],
    intervalId: null,
    activityId: undefined,
    timeStampFrom: moment().unix(),
    timeStampTo: moment().subtract(1, 'hours').unix(),
    loadingSpinner: true,
    loadingTable: true,
    disableRefreshTable: true,
    selectorModel: {
      addActiveHarvestDate: false,
      ...selectorService.getSelectorModel(false)
    },
    sectorFilter: {
      id: 'sector',
      name: i18n.t('headers.sector'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    activityFilter: {
      id: 'activity',
      name: i18n.t('headers.activity'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    thingTypeFilter: {
      id: 'thingType',
      name: i18n.t('headers.thingType'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    workFrontFilter: {
      id: 'workFront',
      name: i18n.t('headers.workFront'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    driveUnitFilter: {
      id: 'driveUnit',
      name: i18n.t('driveUnits.title'),
      show: false,
      showDialog: false,
      disabled: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    timeFormat: null,
    showSelector: false,
    chartComponentModel: {
      showRangeSelector: false,
      rangeSelectorData: []
    },
    tabs: [
      {
        id: 'tab-summary',
        name: i18n.t('driveUnits.reports.harvestedArea.title')
      }
    ],
    selectedTab: 'tab-summary',
    breadcrumbButtonsHarvestedSummaryReport: [],
    tableConfig: {},
    // driveUnitFilterConfig: null,
    // detailFilterConfig: null,
    reportNameForChart: [{ id: 'reportName', name: `<b>${i18n.t('dashboard.workedArea')}</b>` }],
    // todas las referencias iniciales de los mapas del reporte (para restablecerlas luego)
    mapReferences: [],
    // referencias guardadas del mapa de porcentaje trabajado
    workedAreaPercentageConfig: null,
    // referencias de rangos de velocidades del mapa del detalle
    speedDetailMapConfig: [],
    // backup de selectores
    filterSwitch: true,
    sectors: [],
    activities: [],
    types: [],
    things: [],
    driveUnits: [],
    dateAndTimeRange: TimeRanges.LAST_HOUR,
    dateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    autoReport: false,
    autoReportMinutes: 10,
    driveUnitPolygons: {}
  }),
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // cargo la configuración de la tabla
    await this.getConfig()
    // Inicializo los datos del selector
    await this.initializeSelector()
    const reportKey = this.$route.query.key
    this.getData(reportKey)
  },
  created () {
    // Agrego el botón del breadcrumb para mostrar/ocultar el selector
    this.breadcrumbButtonsHarvestedSummaryReport.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapActions({
      'setAutoScreenshot': 'reportBug/setAutoScreenshot',
      'setErrorMsg': 'reportBug/setErrorMsg',
      'setErrorRequestConfig': 'reportBug/setErrorRequestConfig',
      'setFieldsRequired': 'reportBug/setFieldsRequired',
      'openReportBugDialog': 'reportBug/openReportBugDialog'
    }),
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    /**
     * Para el botón del breadcrumb
     */
    selector () {
      this.showSelector = !this.showSelector
    },
    tabSelected (id) {
      const detailRef = this.$refs['detail']
      if (id === 'tab-summary' && detailRef) {
        this.commitVisible({ visible: false })
        detailRef.loadingSpinner = false
        this.loadingSpinner = true
        this.$refs['harvestedAreaSummaryReportMapComponent'].change()
      } else if (id === 'tab-detail' && detailRef) {
        this.commitVisible({ visible: false })
        detailRef.loadingSpinner = true
        this.loadingSpinner = false
        detailRef.$refs['map-current-status-detail'].change()
      }
      this.selectedTab = id
    },
    updateFilterModelDate (filterData) {
      const filterDataTimeRange = filterData.dateTimeRange || null
      const filterDataTimeType = filterData.customDateTimeRangeType || null

      if (filterDataTimeRange == null && filterDataTimeType == null) {
        if (filterData.from || filterData.to) {
          this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE_AND_TIME
        }
      } else {
        this.selectorModel.selectedDateAndTimeRange = filterDataTimeRange || TimeRanges.LAST_HOUR
        this.selectorModel.selectedDateAndTimeRangeCustomType = filterDataTimeType || CustomTimeRangeTypes.DATE
      }

      if (this.selectorModel.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
        const sinceDateData = ISODateFromUnixTimestamp(filterData.from, true)
        const toDateData = ISODateFromUnixTimestamp(filterData.to, true)

        // Carga las fechas en formato string desde el timestamp
        this.selectorModel.sinceDate = sinceDateData.date
        this.selectorModel.toDate = toDateData.date

        if (this.selectorModel.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE_AND_TIME) {
          this.selectorModel.sinceTime = sinceDateData.time
          this.selectorModel.toTime = toDateData.time
        }
      }
    },
    /**
     * Para deshabilitar el botón del breadcrumb
     */
    disableSelectorButton () {
      return this.disableSelector
    },
    /**
     * Incializador del selector
     */
    async initializeSelector () {
      this.selectorModel.filters.push(
        this.sectorFilter,
        this.activityFilter,
        this.thingTypeFilter,
        this.workFrontFilter,
        this.driveUnitFilter,
      )

      // callback cuando se seleccionan items en los filtros
      this.sectorFilter.selectAction = this.sectorFilterCallback.bind(this)
      this.activityFilter.selectAction = this.activityFilterCallback.bind(this)
      this.thingTypeFilter.selectAction = this.thingTypeFilterCallback.bind(this)
      this.workFrontFilter.selectAction = this.disableDriveUnitFilter.bind(this)
      this.driveUnitFilter.selectAction = this.disableWorkFrontFilter.bind(this)
      // datos para el selector de actividades
      const sectorResponse = await sectorApi.getAll()
      this.sectorFilter.data.cleanAndUpdate(sectorResponse.data)
      if (sectorResponse.data.length === 1 && sectorResponse.data[0].key === 'NO_SECTOR') {
        this.sectorFilter.selectedData = this.sectorFilter.data.filter(s => s.key === 'NO_SECTOR')
        this.sectorFilter.disabled = true
        this.sectorFilter.hide = true
        await this.sectorFilterCallback()
      }
      // datos para el selector de unidades de manejo
      const driveUnits = await driveUnitApi.getStructureByEnterprise()
      const hasDriveUnits = this.enterpriseHasDriveUnits(driveUnits)
      if (hasDriveUnits) {
        this.driveUnitFilter.data.cleanAndUpdate(driveUnits.structure)
      } else {
        this.driveUnitFilter.data.cleanAndUpdate([])
        this.sectorFilter.disabled = true
        this.activityFilter.disabled = true
        this.thingTypeFilter.disabled = true
        this.workFrontFilter.disabled = true
        this.driveUnitFilter.disabled = true
        this.selectorModel.disableGetReportButton = true
        const that = this
        this.showSnackbar(
          { visible: true,
            text: that.$t('driveUnits.noData'),
            timeout: 6000,
            style: SnackbarStyle.ERROR,
            showCustomBtn: true,
            customBtnIcon: 'mdi-email-send',
            customBtnTooltip: i18n.t('reportBug.report'),
            customBtnAction: () => {
              html2canvas(document.body).then(function (canvas) {
                const base64 = canvas.toDataURL().split(';base64,')[1]
                that.setAutoScreenshot(base64)
                that.setErrorMsg(that.$t('driveUnits.noData'))
                that.setFieldsRequired(false)
                that.openReportBugDialog()
              })
            }
          }
        )
      }
      const activeHarvest = await harvestService.getActiveHarvest()
      if (activeHarvest.activeHarvestDate) {
        localStorage.setItem('harvestStartTime', activeHarvest.activeHarvestDate)
      }

    },
    async sectorFilterCallback () {
      if (this.sectorFilter.selectedData.length) {
        this.activityFilter.selectedData = []
        this.activityFilter.disabled = false
        this.thingTypeFilter.selectedData = []
        this.thingTypeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        this.driveUnitFilter.disabled = false
        // datos para el selector de actividades
        const activitiesResponse = await activityApi.getAllForSelector(this.sectorFilter.selectedData[0].key, 'WORKED_AREA')
        this.activityFilter.data.cleanAndUpdate(activitiesResponse.data)
        if (activitiesResponse.data.length === 1 && activitiesResponse.data[0].key === 'NO_ACTIVITY') {
          this.activityFilter.selectedData = this.activityFilter.data.filter(s => s.key === 'NO_ACTIVITY')
          this.activityFilter.disabled = true
          this.activityFilter.hide = true
          this.activityFilterCallback()
        }
      } else {
        this.activityFilter.selectedData = []
        this.activityFilter.disabled = true
        this.thingTypeFilter.selectedData = []
        this.thingTypeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        this.driveUnitFilter.disabled = false
      }
    },
    async activityFilterCallback () {
      if (this.activityFilter.selectedData.length) {
        this.thingTypeFilter.selectedData = []
        this.thingTypeFilter.disabled = false
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        this.driveUnitFilter.disabled = false
        const thingTypesResponse =
          await thingApi.getThingTypesByActivity(this.activityFilter.selectedData.map(a => a.id), this.sectorFilter.selectedData[0].key, 'WORKED_AREA')
        this.thingTypeFilter.data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.thingTypeFilter.selectedData = []
        this.thingTypeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        this.driveUnitFilter.disabled = false
      }
    },
    async thingTypeFilterCallback () {
      if (this.thingTypeFilter.selectedData.length) {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = this.driveUnitFilter.selectedData.length !== 0;
        this.driveUnitFilter.disabled = false
        const thingTypesResponse =
          await thingApi.getWorkFrontsByTypes(this.thingTypeFilter.selectedData.map(t => t.id), this.sectorFilter.selectedData[0].key, this.activityFilter.selectedData[0].id)
        this.workFrontFilter.data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.workFrontFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.driveUnitFilter.disabled = false
      }
    },
    applySavedMapReferences (maps) {
      /*
      maps.forEach(map => {
        map.groups.forEach(group => {
          group.series.forEach(serie => {
            serie.points.forEach(point => {
              if (!point.value) {
                point.value = 0
              }
            })
          })
        })
      })
      if (this.driveUnitFilterConfig) {
        maps.forEach((map, i) => {
          map.groups.forEach(group => {
            const ref = this.driveUnitFilterConfig[i].find(r => r.name === group.name)
            group.references = ref.references || group.references
          })
        })
      }
      */

      // guardo las referencias iniciales para restablecerlas luego
      this.mapReferences = []
      maps.forEach((map) => {
        const references = []
        map.groups.forEach(group => {
          references.push({ name: group.name, references: group.references })
        })
        this.mapReferences.push(references)
      })

      // solamente se cargan las referencias guardadas del primer mapa, lo demás no, porque están relacionados con el reporte generado
      // TO DO: agregar las constantes al common-domain, no tenerlas hardcoded
      if (this.workedAreaPercentageConfig != null) {
        const workedAreaMap = maps[0]
        if (workedAreaMap != null) {
          const group = workedAreaMap.groups.find(group => group.name === 'workedPercentage')
          if (group != null) {
            group.references.cleanAndUpdate(this.workedAreaPercentageConfig)
          }
        }
      }

      this.mapConfiguration = maps
    },
    refreshTable () {
      const conversion = getDateTimeRange(this.dateAndTimeRange, this.dateAndTimeRangeCustomType,
        this.sinceDate, this.sinceTime, this.toDate, this.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      if (this.driveUnits.length) {
        this.loadingTable = true
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        workedAreaService.getAreaReportByDriveUnits(
          this.driveUnits, this.types, this.activities, this.sectors,
          this.timeStampFrom, this.timeStampTo, this.timeFormat)
          .then(response => {
            this.tableData.cleanAndUpdate(response.data)
            this.chartConfiguration.cleanAndUpdate(response.charts)
            this.applySavedMapReferences(response.maps)
            this.driveUnitPolygons = response.driveUnitPolygons
            if (this.loadingSpinner) this.commitVisible({ visible: false })
            this.loadingTable = false
          })
      } else if (this.things.length) {
        if (this.loadingSpinner) this.commitVisible({ visible: true })
        this.loadingTable = true
        workedAreaService.getAreaReportByThings(this.timeStampFrom, this.timeStampTo,
          this.things, this.types, this.activities, this.sectors, this.timeFormat)
          .then(response => {
            this.tableData.cleanAndUpdate(response.data)
            this.chartConfiguration.cleanAndUpdate(response.charts)
            this.applySavedMapReferences(response.maps)
            this.driveUnitPolygons = response.driveUnitPolygons
            if (this.loadingSpinner) this.commitVisible({ visible: false })
            this.loadingTable = false
          })
      }
    },
    resize () {
      this.$eventBus.$emit('resizeCharts')
    },
    getData (reportKey) {
      if (reportKey) {
        this.showSelector = false
        this.disableRefreshTable = true
        reportStorageApi.getReport(reportKey).then(response => {
          // proceso los datos
          const { data, filters } = response.data
          const processedSummary = workedAreaService.processSummaryData(data, filters.from, filters.to)

          this.tableHeaders.cleanAndUpdate(processedSummary.headers)
          this.setTableFilterRules(processedSummary.headers)
          this.tableData.cleanAndUpdate(processedSummary.data)
          this.chartConfiguration.cleanAndUpdate(processedSummary.charts)
          this.applySavedMapReferences(processedSummary.maps)
          this.driveUnitPolygons = processedSummary.driveUnitPolygons
          this.setFilterData(filters)
        })
      } else {
        this.showSelector = true
      }
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    clickRow (data) {
      this.setEntityConfiguration({ name: this.$t('harvesters.title'), value: 'driveUnitId', text: 'driveUnitName' })
      const items = []
      this.tableData.forEach(harvester => {
        items.push(
          { driveUnitId: harvester.driveUnitId,
            driveUnitName: harvester.driveUnitName,
            activities: this.activities.map(a => a.id),
            types: this.types.map(t => t.id),
            timeStampFrom: this.timeStampFrom,
            timeStampTo: this.timeStampTo,
            autoReport: this.autoReport,
            autoReportMinutes: this.autoReportMinutes,
            thingIds: this.things,
            sector: this.sectors
          })
      })
      this.setEntitySelectorItems(items)
      this.setSelectedItem(
        { driveUnitId: data.driveUnitId,
          driveUnitName: data.driveUnitName,
          activities: this.activities.map(a => a.id),
          types: this.types.map(t => t.id),
          timeStampFrom: this.timeStampFrom,
          timeStampTo: this.timeStampTo,
          autoReport: this.autoReport,
          autoReportMinutes: this.autoReportMinutes,
          thingIds: this.things,
          sector: this.sectors
        })
      if (!this.tabs.filter(tab => tab.id === 'tab-detail').length) {
        this.tabs.push({
          id: 'tab-detail',
          name: i18n.t('detail')
        })
      }
      this.selectedTab = 'tab-detail'
    },
    /**
     * Evento lanzado por el selector cuando se cierra con la X
     */
    close () {
      this.showSelector = false
    },
    getReport (eventData) {
      // guardo los datos elegidos en los selectores
      this.filterSwitch = eventData.filterSwitchValue
      if (eventData.filterSwitchValue) {
        if (this.sectorFilter.hide) {
          this.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
        } else {
          this.sectors.clean()
        }
        if (this.activityFilter.hide) {
          this.activities.cleanAndUpdate(this.activityFilter.selectedData)
        } else {
          this.activities.clean()
        }
        this.types.clean()
        this.driveUnits.clean()
        this.things.cleanAndUpdate(eventData.groupEquipmentFilterSelected)
      } else {
        this.sectors.cleanAndUpdate(this.sectorFilter.selectedData)
        this.activities.cleanAndUpdate(this.activityFilter.selectedData)
        this.types.cleanAndUpdate(this.thingTypeFilter.selectedData)
        this.things.cleanAndUpdate(this.workFrontFilter.selectedData)
        this.driveUnits.cleanAndUpdate(this.driveUnitFilter.selectedData)
      }
      this.dateAndTimeRange = eventData.dateAndTimeRange
      this.dateAndTimeRangeCustomType = eventData.dateAndTimeRangeCustomType
      this.sinceDate = eventData.sinceDate
      this.sinceTime = eventData.sinceTime
      this.toDate = eventData.toDate
      this.toTime = eventData.toTime
      this.autoReport = eventData.autoReport
      this.autoReportMinutes = eventData.autoReportMinutes

      const conversion = getDateTimeRange(eventData.dateAndTimeRange, eventData.dateAndTimeRangeCustomType,
        eventData.sinceDate, eventData.sinceTime, eventData.toDate, eventData.toTime)
      this.timeStampFrom = conversion.tFrom
      this.timeStampTo = conversion.tTo
      this.timeFormat = eventData.dateAndTimeFormat
      const that = this
      if (!eventData.filterSwitchValue && this.driveUnits.length) {
        if (eventData.autoReport) {
          that.loadingTable = true
          that.disableRefreshTable = false
          if (that.loadingSpinner) that.commitVisible({ visible: true })
          workedAreaService.getAreaReportByDriveUnits(that.driveUnits, that.types, that.activities, that.sectors, that.timeStampFrom, that.timeStampTo, that.timeFormat)
            .then(response => {
              that.tableHeaders.cleanAndUpdate(response.headers)
              that.setTableFilterRules(response.headers)
              that.tableData.cleanAndUpdate(response.data)
              that.chartConfiguration.cleanAndUpdate(response.charts)
              that.applySavedMapReferences(response.maps)
              that.driveUnitPolygons = response.driveUnitPolygons
              if (that.loadingSpinner) that.commitVisible({ visible: false })
              that.loadingTable = false
              that.setReportDetails()
            })
          clearInterval(that.intervalId)
          that.intervalId = setInterval(
            function () {
              const conversion = getDateTimeRange(that.dateAndTimeRange, that.dateAndTimeRangeCustomType,
                that.sinceDate, that.sinceTime, that.toDate, that.toTime)
              that.timeStampFrom = conversion.tFrom
              that.timeStampTo = conversion.tTo
              if (that.loadingSpinner) that.commitVisible({ visible: true })
              that.loadingTable = true
              workedAreaService.getAreaReportByDriveUnits(that.driveUnits, that.types, that.activities, that.sectors, that.timeStampFrom, that.timeStampTo, that.timeFormat)
                .then(response => {
                  that.tableData.cleanAndUpdate(response.data)
                  that.chartConfiguration.cleanAndUpdate(response.charts)
                  that.applySavedMapReferences(response.maps)
                  that.driveUnitPolygons = response.driveUnitPolygons
                  if (that.loadingSpinner) that.commitVisible({ visible: false })
                  that.loadingTable = false
                })
            },
            that.autoReportMinutes * 60 * 1000)
        } else {
          workedAreaService.getAreaReportByDriveUnitsDeferred(
            that.driveUnits, this.types, this.activities, this.sectors, this.timeStampFrom, this.timeStampTo,
            this.dateAndTimeRange, this.dateAndTimeRangeCustomType, this.$route.path, this.timeFormat, this.filterSwitch)
          this.showReportDefer({ updateFunction: this.getData.bind(this) })
        }
      } else if (this.things.length) {
        if (eventData.autoReport) {
          if (that.loadingSpinner) that.commitVisible({ visible: true })
          that.loadingTable = true
          that.disableRefreshTable = false
          workedAreaService.getAreaReportByThings(that.timeStampFrom, that.timeStampTo,
            that.things, that.types, that.activities, that.sectors, that.timeFormat)
            .then(response => {
              that.tableHeaders.cleanAndUpdate(response.headers)
              that.setTableFilterRules(response.headers)
              that.tableData.cleanAndUpdate(response.data)
              that.chartConfiguration.cleanAndUpdate(response.charts)
              that.applySavedMapReferences(response.maps)
              that.driveUnitPolygons = response.driveUnitPolygons
              if (that.loadingSpinner) that.commitVisible({ visible: false })
              that.loadingTable = false
              that.setReportDetails()
            })
          clearInterval(that.intervalId)
          that.intervalId = setInterval(
            function () {
              const conversion = getDateTimeRange(that.dateAndTimeRange, that.dateAndTimeRangeCustomType,
                that.sinceDate, that.sinceTime, that.toDate, that.toTime)
              that.timeStampFrom = conversion.tFrom
              that.timeStampTo = conversion.tTo
              if (that.loadingSpinner) that.commitVisible({ visible: true })
              that.loadingTable = true
              workedAreaService.getAreaReportByThings(that.timeStampFrom, that.timeStampTo,
                that.things, that.types, that.activities, that.sectors, that.timeFormat)
                .then(response => {
                  that.tableData.cleanAndUpdate(response.data)
                  that.chartConfiguration.cleanAndUpdate(response.charts)
                  that.applySavedMapReferences(response.maps)
                  that.driveUnitPolygons = response.driveUnitPolygons
                  if (that.loadingSpinner) that.commitVisible({ visible: false })
                  that.loadingTable = false
                })
            },
            eventData.autoReportMinutes * 60 * 1000)
        } else {
          workedAreaService.getAreaReportByThingsDeferred(
            this.things, this.types,
            this.activities, this.sectors, this.timeStampFrom, this.timeStampTo,
            this.dateAndTimeRange, this.dateAndTimeRangeCustomType, this.$route.path, this.timeFormat, this.filterSwitch)
          this.showReportDefer({ updateFunction: this.getData.bind(this) })
        }
      }
      this.showSelector = false
    },
    setReportDetails () {
      const detailData = {
        from: this.timeStampFrom,
        to: this.timeStampTo,
        activities: this.activities,
        sector: this.sectors,
        types: this.types,
        reportName: this.reportNameForChart
      }
      const dataStructure = [
        { detailDataProp: 'reportName', propTitle: this.$t('report'), propType: 'LIST', propRefs: { list: this.reportNameForChart, value: 'id', text: 'name' } },
        { detailDataProp: 'from', propTitle: this.$t('since'), propType: 'DATE' },
        { detailDataProp: 'to', propTitle: this.$t('to'), propType: 'DATE' },
        { detailDataProp: 'sector', propTitle: this.$t('headers.sector'), propType: 'LIST', propRefs: { list: this.sectors, value: 'id', text: 'name' } },
        { detailDataProp: 'activities', propTitle: this.$t('activity'), propType: 'LIST', propRefs: { list: this.activities, value: 'id', text: 'name' } },
        { detailDataProp: 'types', propTitle: this.$t('headers.thingType'), propType: 'LIST', propRefs: { list: this.types, value: 'id', text: 'name' } }
      ]
      if (this.driveUnits.length) {
        const driveUnits = []
        getChildrenFromList(this.driveUnits, driveUnits)
        detailData.driveUnits = driveUnits
        dataStructure.push({
          detailDataProp: 'driveUnits',
          propTitle: this.$t('driveUnitsFilter'),
          propType: 'LIST',
          propRefs: { list: driveUnits, value: 'id', text: 'name' } })
      } else if (this.things.length) {
        const things = []
        getChildrenFromList(this.things, things)
        detailData.things = things
        dataStructure.push({
          detailDataProp: 'things',
          propTitle: this.$t('things'),
          propType: 'LIST',
          propRefs: { list: things, value: 'id', text: 'name' } })
      }
      this.setDetails({
        detailData,
        dataStructure
      })
    },
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
    /**
     * TO DO: cargar fechas y horas al modelo del selector
     * @param {*} filterData
     */
    async setFilterData (filterData) {
      if (filterData) {
        // switch de tipo de filtrado
        this.selectorModel.filterSwitch.value = filterData.filterSwitch
        this.filterSwitch = filterData.filterSwitch

        // Filtro de sectores
        if (this.sectorFilter.data.length && filterData.sector && filterData.sector.length) {
          this.sectors.cleanAndUpdate(filterData.sector)
          this.sectorFilter.selectedData.cleanAndUpdate(filterData.sector)
          await this.sectorFilterCallback()
        }

        // Filtro de actividades
        if (this.activityFilter.data.length && filterData.activities && filterData.activities.length) {
          this.activityId = filterData.activities[0].id
          this.activities.cleanAndUpdate(filterData.activities)
          this.activityFilter.selectedData.cleanAndUpdate(filterData.activities)
          await this.activityFilterCallback()
        }

        // Filtro de tipos
        if (this.thingTypeFilter.data.length && filterData.types && filterData.types.length) {
          this.thingTypeFilter.selectedData.cleanAndUpdate(filterData.types)
          this.types.cleanAndUpdate(filterData.types)
          await this.thingTypeFilterCallback()
        }

        // Filtro de frentes/equipos
        if (filterData.things && filterData.things.length) {
          this.things.cleanAndUpdate(filterData.things)
          if (this.filterSwitch && this.selectorModel.groupEquipmentFilter.data.length) {
            this.selectorModel.groupEquipmentFilter.selectedData.cleanAndUpdate(filterData.things)
          } else if (!this.filterSwitch && this.workFrontFilter.data.length) {
            this.workFrontFilter.selectedData.cleanAndUpdate(filterData.things)
            this.disableDriveUnitFilter()
          }
        }

        // Filtro de unidades de manejo
        if (this.driveUnitFilter.data.length && filterData.driveUnits && filterData.driveUnits.length) {
          this.driveUnits.cleanAndUpdate(filterData.driveUnits)
          this.driveUnitFilter.selectedData.cleanAndUpdate(filterData.driveUnits)
          this.disableWorkFrontFilter()
        }

        // rango de tiempo
        this.timeStampFrom = filterData.from
        this.timeStampTo = filterData.to
        this.selectorModel.selectedDateAndTimeRange = filterData.dateTimeRange
        this.selectorModel.selectedDateAndTimeRangeCustomType = filterData.customDateTimeRangeType
        this.selectorModel.sinceDate = filterData.sinceDate
        this.selectorModel.sinceTime = filterData.sinceTime
        this.selectorModel.toDate = filterData.toDate
        this.selectorModel.toTime = filterData.toTime
        this.selectorModel.selectedTimeFormat = filterData.timeFormat
        this.timeFormat = filterData.timeFormat
        this.dateAndTimeRange = filterData.dateTimeRange
        this.dateAndTimeRangeCustomType = filterData.customDateTimeRangeType
        this.sinceDate = filterData.sinceDate
        this.sinceTime = filterData.sinceTime
        this.toDate = filterData.toDate
        this.toTime = filterData.toTime

        this.updateFilterModelDate(filterData)
        this.setReportDetails()
        this.validateSelector()
      }
    },
    disableWorkFrontFilter () {
      if (this.driveUnitFilter.selectedData.length) {
        this.workFrontFilter.disabled = true
        this.workFrontFilter.selectedData.clean()
      } else if (this.thingTypeFilter.selectedData.length) {
        this.workFrontFilter.disabled = false
      }
    },
    disableDriveUnitFilter () {
      if (this.workFrontFilter.selectedData.length) {
        this.driveUnitFilter.disabled = true
        this.driveUnitFilter.selectedData.clean()
      } else {
        this.driveUnitFilter.disabled = false
      }
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const thingSelected = that.selectorModel.groupEquipmentFilter.selectedData
        const sectorSelected = that.sectorFilter.selectedData
        const activitySelected = that.activityFilter.selectedData
        const thingTypeSelected = that.thingTypeFilter.selectedData
        const workFrontSelected = that.workFrontFilter.selectedData
        const driveUnitSelected = that.driveUnitFilter.selectedData
        const customTimeValidation = selectorDateTimeValidation(that.selectorModel.selectedDateAndTimeRange,
          that.selectorModel.selectedDateAndTimeRangeCustomType, that.selectorModel.customDateTimeValidForm,
          that.selectorModel.sinceDate, that.selectorModel.sinceTime, that.selectorModel.toDate, that.selectorModel.toTime)
        const timeFormatSelected = that.selectorModel.selectedTimeFormat
        that.selectorModel.disableGetReportButton =
          (that.selectorModel.showGroupEquipmentFilter && that.selectorModel.filterSwitch.value && thingSelected.length === 0) ||
          ((!that.selectorModel.showGroupEquipmentFilter || !that.selectorModel.filterSwitch.value) &&
            (sectorSelected.length === 0 || activitySelected.length === 0 || thingTypeSelected.length === 0 ||
              (workFrontSelected.length === 0 && driveUnitSelected.length === 0))) ||
          customTimeValidation ||
          !timeFormatSelected
      })
    },
    /**
     * Para cargar la configuración de la tabla
     */
    async getConfig () {
      const config = await configurationService.get('harvested-area-report')
      if (config && config.data) {
        this.tableConfig = config.data.table ? config.data.table : {}
        // this.driveUnitFilterConfig = config.data.driveUnitFilter ? config.data.driveUnitFilter : null
        this.workedAreaPercentageConfig = config.data.workedAreaPercentageConfig ? config.data.workedAreaPercentageConfig : null
        // this.detailFilterConfig = config.data.detailFilter ? config.data.detailFilter : null
        this.speedDetailMapConfig = config.data.speedDetailMapConfig ? config.data.speedDetailMapConfig : []
      }
    },
    /**
     * Para guardar la configuración de la tabla
     * @param {*} config
     */
    saveConfig (config) {
      configurationService.save('harvested-area-report', {
        table: config,
        // driveUnitFilter: this.driveUnitFilterConfig,
        workedAreaPercentageConfig: this.workedAreaPercentageConfig,
        // detailFilter: this.detailFilterConfig
        speedDetailMapConfig: this.speedDetailMapConfig
      }).then(() => {
        this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
        this.getConfig()
      }).catch(() => {
        this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
      })
    },
    mapReferenceChange (references) {
      // SOLO SE GUARDAN LAS REFERENCIAS DEL MAPA DE PORCENTAJE TRABAJADO
      // las referencias del mapa de frentes son datos relacionados con el reporte generado
      const workedAreaMapReferences = references[0]
      if (workedAreaMapReferences) {
        const workedAreaGroupReferences = workedAreaMapReferences.find(group => group.name === 'workedPercentage')
        if (workedAreaGroupReferences) {
          if (this.workedAreaPercentageConfig == null) {
            this.workedAreaPercentageConfig = workedAreaGroupReferences.references
          } else {
            this.workedAreaPercentageConfig.cleanAndUpdate(workedAreaGroupReferences.references)
          }
        }
      }
      configurationService.save('harvested-area-report', {
        table: this.tableConfig,
        // driveUnitFilter: references,
        workedAreaPercentageConfig: this.workedAreaPercentageConfig,
        // detailFilter: this.detailFilterConfig
        speedDetailMapConfig: this.speedDetailMapConfig
      })
    },
    mapResetFilter () {
      // TO DO: NO USAR LOS ÍNDICES DE LOS MAPAS, USAR UN IDENTIFICADOR DEL MAPA Y GRUPO
      // SI LOS MAPAS SE CAMBIAN, ESTO FALLARÁ

      /*
      let refsReset = references
      if (mapsId[0] === idMap) {
        refsReset[0][0].references = areaPercentageMap.references
      } else if (mapsId[1] === idMap) {
        refsReset[1][0].references = []
      }
      this.driveUnitFilterConfig = refsReset
      */
      if (this.workedAreaPercentageConfig == null) {
        this.workedAreaPercentageConfig = areaPercentageMap.references
      } else {
        this.workedAreaPercentageConfig.cleanAndUpdate(areaPercentageMap.references)
      }
      const mapReference = this.mapReferences[0]
      if (mapReference) {
        const mapReferenceGroup = mapReference.find(group => group.name === 'workedPercentage')
        if (mapReferenceGroup) {
          mapReferenceGroup.references.cleanAndUpdate(areaPercentageMap.references)
        }
      }
      configurationService.save('harvested-area-report', {
        table: this.tableConfig,
        // driveUnitFilter: refsReset,
        workedAreaPercentageConfig: areaPercentageMap.references,
        // detailFilter: this.detailFilterConfig
        speedDetailMapConfig: this.speedDetailMapConfig
      })

      const maps = clone(this.mapConfiguration)

      maps.forEach((map, i) => {
        map.groups.forEach(group => {
          const filterConfig = this.mapReferences[i]
          if (filterConfig != null) {
            const ref = filterConfig.find(r => r.name === group.name)
            if (ref && ref.references) {
              group.references.cleanAndUpdate(ref.references)
            }
          }
        })
      })

      this.mapConfiguration.cleanAndUpdate(maps)
    },
    /**
     * Solo se guardan las referencias de los rangos de velocidades del mapa de velocidades
     * Los otros mapas muestran datos relacionados con el reporte generado y no corresponde guardar la configuración
     * @param {*} references
     */
    detailMapReferenceChange (references) {
      /*
      this.detailFilterConfig = references
      configurationService.save('harvested-area-report', {
        table: this.tableConfig,
        driveUnitFilter: this.driveUnitFilterConfig,
        detailFilter: references
      })
      */
      this.speedDetailMapConfig.cleanAndUpdate(references)
      configurationService.save('harvested-area-report', {
        table: this.tableConfig,
        workedAreaPercentageConfig: this.workedAreaPercentageConfig,
        speedDetailMapConfig: references
      })
    },
    /**
     * Validar que una empresa tenga unidades de manejos
     */
    enterpriseHasDriveUnits (driveUnits) {
      let hasDriveUnits = false
      if (driveUnits && driveUnits.structure) {
        const structureDriveUnits = []
        getChildrenFromList(driveUnits.structure, structureDriveUnits)
        if (structureDriveUnits.length) {
          hasDriveUnits = true
        }
      }
      return hasDriveUnits
    }
  },
  watch: {
    selectorModel: {
      handler: function (newModel) {
        this.validateSelector()
        const hasActiveHarvest = localStorage.getItem('harvestStartTime')
        this.selectorModel.addActiveHarvestDate = !!(hasActiveHarvest
          && newModel.filters
          && newModel.filters[4]
          && newModel.filters[4].selectedData
          && newModel.filters[4].selectedData.length)
      },
      deep: true
    },
  }
}
